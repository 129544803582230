<script setup lang="ts">
import { Btn, Card, Formal, FormalInput, Icon } from 'webcc-ui-components'
import type { BasicOwnerNumberField, BasicPasswordField } from '#components'

const auth = useAuth()
const route = useRoute()
const { $rememberMe, $ROUTE, $consent } = useNuxtApp()

const formRef = ref<InstanceType<typeof Formal>>()
const passwordRef = ref<InstanceType<typeof BasicPasswordField>>()
const ownerNumberRef = ref<InstanceType<typeof BasicOwnerNumberField>>()
const tracking = useLoginTracking()

const [submitted, toggleSubmitted] = useToggle()

const formData = reactive({
  ownerNumber: '',
  password: '',
  rememberMe: false,
})

if (route.query.uid) $rememberMe.set(route.query.uid as string, true)

const ownerNumberValidationString = computed(() => {
  if (submitted.value && auth.error) return 'force-show-error'
  return undefined
})
const passwordValidationString = computed(() => {
  if (submitted.value && auth.error) return 'force-show-error'
  return 'required'
})

watch(
  () => formData.password,
  () => toggleSubmitted(false),
)
watch(
  () => formData.ownerNumber,
  (ownerNumber: string) => {
    toggleSubmitted(false)
    auth.userID = ownerNumber
    $rememberMe.set(ownerNumber, formData.rememberMe)
  },
)
watch(
  () => formData.rememberMe,
  (nextValue) => $rememberMe.set(formData.ownerNumber, nextValue),
)
watch(
  () => auth.error,
  () => nextTick(() => formRef.value?.validateForm()),
)
watch(
  () => $consent.consentOpened,
  async (isOpened) => {
    if (!isOpened) {
      await nextTick()
      if (!$rememberMe.get()) return focusOwnerNumberOnOpen()
      if ($rememberMe.has()) return focusPasswordOnOpen()
    }
  },
)

async function login() {
  $rememberMe.set(formData.ownerNumber, formData.rememberMe)
  toggleSubmitted(true)
  await auth.login(formData.ownerNumber, formData.password)
}

function focusPasswordOnOpen() {
  /** Focusing on password input element */
  const passwordInput = passwordRef.value?.$el.querySelector('input')
  passwordInput?.focus()
}

function focusOwnerNumberOnOpen() {
  /** Focusing on owner number input element */
  const ownerNumberField = ownerNumberRef.value?.$el.querySelector('input')
  ownerNumberField?.focus()
}

function handleForgotNumberRedirect() {
  tracking.login.trackForgotNumberView()
  navigateTo($ROUTE.FORGOT_NUMBER)
}

function handleForgotPasswordRedirect() {
  tracking.login.trackForgotPasswordView()
  navigateTo($ROUTE.FORGOT_PASSWORD)
}

onMounted(() => {
  /** Restoring data from localStorage */
  const rememberMeEnabled = $rememberMe.has()
  formData.ownerNumber = $rememberMe.get()
  formData.rememberMe = rememberMeEnabled
})
</script>

<template>
  <Card compact class="relative py-4 lg:p-6">
    <div class="mb-2 flex justify-end">
      <div class="grid place-items-center">
        <BasicTooltip> {{ $t('components.login.infobox') }}</BasicTooltip>
      </div>
    </div>
    <Formal ref="formRef" v-model="formData" name="login" @submit="login">
      <div
        v-if="submitted && $auth.error"
        class="relative mb-2 flex h-14 items-center justify-center rounded border border-err bg-bgr pl-11 text-base font-normal leading-5 text-txt transition-all"
      >
        {{ $t('components.login.loginFailed') }}

        <div
          class="pointer-events-none absolute top-0 left-0 bottom-0 flex items-center justify-center"
        >
          <Icon
            size="md"
            graphic="spam"
            class="pointer-events-none mx-2 text-err"
          />
        </div>
      </div>

      <BasicOwnerNumberField
        ref="ownerNumberRef"
        class="mb-3.5 lg:mb-[18px] pb-2.5"
        autofocus
        :disabled="$consent.consentOpened"
        :validation="ownerNumberValidationString"
      />

      <div class="flex justify-end">
        <Btn
          class="mb-[26.5px] font-normal"
          size="sm"
          variant="link"
          @click="handleForgotNumberRedirect"
        >
          {{ $t('components.login.forgotNumber') }}
        </Btn>
      </div>

      <BasicPasswordField
        ref="passwordRef"
        name="password"
        :label="$t('components.login.password')"
        class="mb-3.5 lg:mb-[18px] pb-2.5"
        :dynamic-bottom-margin="true"
        :validation="passwordValidationString"
        :validation-messages="{
          required: $t('components.validation.fieldRequired'),
        }"
        :disabled="$consent.consentOpened"
        autocomplete="current-password"
      />

      <div class="flex justify-end">
        <Btn
          class="mb-[29.5px] font-normal"
          size="sm"
          variant="link"
          @click="handleForgotPasswordRedirect"
        >
          {{ $t('components.login.forgotPassword') }}
        </Btn>
      </div>

      <div class="mb-[27px]">
        <FormalInput
          id="rememberMe"
          ref="rememberMe"
          type="checkbox"
          name="rememberMe"
          :label="$t('components.login.rememberMe')"
        />
      </div>

      <Btn
        variant="theme"
        type="submit"
        size="lg"
        class="min-w-full justify-center"
        :loading="$auth.loading"
        :disabled="$auth.loading"
        :label="$t('components.common.login')"
      />
    </Formal>
  </Card>
</template>
